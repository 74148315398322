import React from 'react';

import { TextSectionProps } from './types';
import * as Styled from './styles';
import RichText from '../../molecules/RichText';

const TextSection: React.FC<TextSectionProps> = ({ data }) => {
  const { innerMode, content } = data;

  return (
    <Styled.CustomInner {...{ innerMode }}>
      <RichText data={content} />
    </Styled.CustomInner>
  );
};

export default TextSection;
