import { graphql } from 'gatsby';
import React from 'react';

import { BLOG_INNER_MAX_WIDTH } from '../../const';
import TextInnerSection from '../../TextSection';
import { TextSectionProps } from './types';
import { inInnerModeType } from './utils';

const TextSection: React.VFC<TextSectionProps> = ({ content, innerMode }) => {
  const isInInnerModeType = inInnerModeType(innerMode);

  return (
    <TextInnerSection
      data={{
        content: content ?? undefined,
        innerMode: isInInnerModeType ? innerMode : BLOG_INNER_MAX_WIDTH,
      }}
    />
  );
};

export const fragment = graphql`
  fragment TextSectionFragment on SanityTextSection {
    _key
    _type
    innerMode
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default TextSection;
