import { InnerMode } from './enums';

export const InnerModeMap = {
  [InnerMode.Default]: '100%',
  [InnerMode.XL]: '93rem',
  [InnerMode.L]: '85rem',
  [InnerMode.M]: '78rem',
  [InnerMode.S]: '56.75rem',
  [InnerMode.XS]: '50.5rem',
  [InnerMode.XXS]: '48.125rem',
};
