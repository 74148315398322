import { InnerMode } from '../../TextSection/enums';

export const inInnerModeType = (value: string | undefined): value is InnerMode => {
  if (!value) {
    return false;
  }
  const innerMode = value.toUpperCase();

  return !!Object.keys(InnerMode).find((key) => key === innerMode);
};
