import styled from 'styled-components';

import { InnerModeMap } from './consts';
import { InnerMode } from './enums';
import { CustomInnerStyledProps } from './types';

export const CustomInner = styled.div<CustomInnerStyledProps>`
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  max-width: ${({ innerMode = InnerMode.Default }) => InnerModeMap[innerMode]};
`;
